<template>
  <div id="app">
    <div class="body fixed mobile wheel-game">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Header -->
      <section class="header point_success">
        <div class="flexH">
          <a
            class="icon iconButton third"
            @click.prevent="goBackTo"
          >
            <font-awesome-icon
              icon="fa-solid fa-chevron-left"
              size="lg"
            ></font-awesome-icon>
          </a>
        </div>
        <span class="fL nowrap">小遊戲</span>
        <div class="flexH hide-opacity">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </div>
        <span class="fM reward-text" @click="toRewardDetail">抽獎結果</span>
      </section>
      <!-- Main -->
      <div class="main-section-wrapper">
        <section class="main point_success items-center main-section">
          <img class="bg" src="@/assets/icon/bg_minigame.png" />
          <Roulette
            v-if="items.length > 0"
            class="mt-4"
            ref="wheel"
            base-display
            centered-indicator
            display-indicator
            display-border
            :display-shadow="false"
            :items="items"
            :first-item-index="firstIdx"
            :wheel-result-index="wheelResultIndex"
            @wheel-end="onWheelEnd"
          >
            <template #baseContent>
              <img width="100" src="@/assets/icon/uide/coin.svg" alt="" />
            </template>
          </Roulette>
          <button class="click-btn button text-white fM border-0 mt-4" :disabled="drawDisabled" @click="startGame">
            開始抽獎
          </button>
          <p class="can-draw-count" v-if="isRegular">
            <span>可抽獎次數</span>
            <span class="hit-text">{{ drawTimes }}</span>
            <span>次</span>
          </p>
          <p v-if="isRegular && drawTimes <= 0" class="no-draw-text">抱歉，您暫無抽獎資格，可藉由完成任務來增加您的抽獎次數！</p>
        </section>
      </div>
    </div>
    <ConfirmDialog
      ref="resultDialogRef"
      :type="dialogInfo.type"
      :title="dialogInfo.title"
      :content="dialogInfo.content"
      :buttons="dialogInfo.buttons"
    />
    <AlertDialog
      v-if="showAlert"
      :title="alert.title"
      :content="alert.message"
      @close="onCloseAlertDialog"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

import Roulette from "../components/Roulette.vue";
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue'
import AlertDialog from '@/components/Dialog/AlertDialog.vue'

export default {
  name: "WheelGame",
  components: {
    Roulette,
    ConfirmDialog,
    AlertDialog
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      firstIdx: { value: 0 },
      wheelResultIndex: { value: 2 },
      drawing: false,
      resultTitle: "感謝您中獎",
      dialogInfo: {},
      showAlert: false,
      alert: {
        title: '',
        message: ''
      },
      // items: [
      //   {
      //     id: 1,
      //     name: "Banana",
      //     htmlContent: "Banana",
      //     textColor: "",
      //     background: "",
      //   },
      //   {
      //     id: 2,
      //     name: "Apple",
      //     htmlContent: "Apple",
      //     textColor: "",
      //     background: "",
      //   },
      //   {
      //     id: 3,
      //     name: "Orange and Purple",
      //     htmlContent: "Orange<br>and Purple",
      //     textColor: "",
      //     background: "",
      //   },
      //   {
      //     id: 4,
      //     name: "Cherry",
      //     htmlContent: "Cherry",
      //     textColor: "",
      //     background: "",
      //   },
      // ],
      campaign: {},
      init: false
    };
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    isRegular() {
      return this.campaign?.campaignPrototype === "minigame-regular";
    },
    drawTimes() {
      return this.campaign?.draw_times || 0;
    },
    drawDisabled() {
      if (this.isRegular) {
        return this.drawing || this.drawTimes <= 0;
      }
      return this.drawing || this.campaign.reward_status;
    },
    items() {
      const missions = JSON.parse(
        JSON.stringify(this.campaign?.missions || [])
      );
      // if (missions.length > 0 && missions.length < 4) {
      //   for (let i = missions.length; i < 4; i++) {
      //     missions.push({
      //       missionName: "銘謝惠顧",
      //     });
      //   }
      // }
      const re = missions?.map((m) => {
        return {
          id: m?.settings?.rewardId,
          name: m.missionName,
          htmlContent: m.missionName,
          textColor: "",
          background: "",
        };
      }) || [];
      return re
    },
    reward() {
      return this.items[this.wheelResultIndex.value];
    },
    resultContent() {
      return `恭喜您獲得 ${this.reward?.name}`;
    }
  },
  mounted() {
    this.getCampaigns();
  },
  methods: {
    onCloseAlertDialog() {
      this.showAlert = false;
      this.alert = {
        title: '',
        message: ''
      }
      this.$router.replace('/home')
    },
    goBackTo() {
      this.$router.go(-1);
    },
    launchWheel() {
      this.$refs.wheel.launchWheel();
    },
    onWheelEnd() {
      if (!this.init) return this.init = true;

      if (this.reward?.name === "銘謝惠顧" || !this.reward?.name) {
        this.dialogInfo = {
          title: "銘謝惠顧",
          content: "感謝您的參與",
          buttons: [
            {
              text: "確認",
              click: ({ close }) => {
                close();
              },
            },
          ],
        }
        this.$refs.resultDialogRef.open()
        return
      }

      this.dialogInfo = {
        title: "恭喜您中獎",
        type: "success",
        content: `恭喜您獲得 ${this.reward?.name}`,
        buttons: [
          {
            text: "查看結果",
            outline: true,
            click: ({ close }) => {
              this.$router.push("/record");
              close();
            },
          },
          {
            text: "確認",
            click: ({ close }) => {
              close();
            },
          },
        ],
      }
      this.$refs.resultDialogRef.open()

      this.getReward()
      this.getCampaigns(false)
      this.drawing = false
    },
    getCampaignAPI() {
      const campaignId = this.$route.params.id
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal&campaignId=${campaignId}`,
        methods: "GET",
      };
      return this.$http(config);
    },
    getCampaigns(init = true) {
      this.getCampaignAPI()
        // this.campaignDummy()
        .then((res) => {
          const c = res.data.campaigns.find(
              (c) => c.campaignId === this.$route.params.id
            );
          if (init) {
            this.campaign = c
          } else {
            this.campaign.reward_status = c.reward_status
            this.campaign.draw_times = c?.draw_times || 0
          }

          console.log('this.campaign', this.campaign);
        })
        .finally(() => {
          this.isCampaignsLoaded = true;
        });
    },
    getReward() {
      const config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${this.$route.params.id}/send-mini-game-reward`,
        method: "POST",
        data: {
          reward_id: this.items[this.wheelResultIndex.value]?.id || 0,
          member_id: this.userInfo.memberId,
        }
      }
      this.$http(config)
    },
    isOverToday(campaign) {
      const today = moment();
      const endTime = moment(campaign.campaignEndTime * 1000);
      return endTime.isBefore(today);
    },
    startGame() {

      if (this.isOverToday(this.campaign)) {
        this.alert = {
          title: '提醒',
          message: '任務已下架'
        }
        this.showAlert = true
        return;
      }

      this.drawing = true
      this.init = true
      const config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${this.$route.params.id}/draw-mini-game`,
        method: "POST",
        data: {
          uuid: this.user.userId,
          member_id: this.userInfo.memberId,
        },
      };
      this.$http(config).then((res) => {
        console.log('res.data.rewardId', res.data.rewardId);
        console.log('this.items', this.items);
        this.wheelResultIndex.value = this.items.findIndex(i => i.id === res.data.rewardId)
        console.log('this.wheelResultIndex.value', this.wheelResultIndex.value);
        this.launchWheel()
      });
    },
    toRewardDetail() {
      this.$router.push(`/campaign/${this.$route.params.id}/mini-game/reward`)
    },
  },
};
</script>

<style scoped lang="scss">
.wheel-game {
  .main-section-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main-section {
    position: relative;
    height: calc(100vh - 120px);
    margin: 0;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 24px;
    justify-content: flex-start;
  }
  .bg {
    position: absolute;
    top: 16px;
    left: -16px;
    right: -16px;
    width: calc(100% + 32px);
    object-fit: contain;
  }
  .click-btn {
    width: 160px;
    margin-top: 36px;
    z-index: 1;
  }
}
.reward-text {
  color: #6B2DF5;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 !important;
}
.point_success {
  justify-content: space-between;
  position: relative;
}
.hide-opacity {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.hit-text {
  margin: 0 12px;
  color: var(--c1);
}
.can-draw-count {
  margin-top: 16px;
  font-weight: bold;
}
.no-draw-text {
  max-width: 280px;
  margin-top: 16px;
  color: #333333;
}
</style>
