var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wheel-container",class:[
    `indicator-${_vm.indicatorPosition}`,
    { 'wheel-container-indicator': _vm.displayIndicator },
    { 'wheel-container-shadow': _vm.displayShadow },
    { 'wheel-container-border': _vm.displayBorder },
  ],attrs:{"id":`wheel-container-${_vm.randomIdRoulette}`}},[(_vm.baseDisplay)?_c('div',{staticClass:"wheel-base-container",class:[{ 'wheel-base-container-shadow': _vm.baseDisplayShadow }],style:({
      width: `${_vm.baseSize}px`,
      height: `${_vm.baseSize}px`,
      background: `${_vm.baseBackground}`,
    })},[_c('div',{staticClass:"wheel-base"},[_vm._t("baseContent")],2),(_vm.baseDisplayIndicator)?_c('div',{staticClass:"wheel-base-indicator"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"wheel",style:({
      width: `${_vm.size}px`,
      height: `${_vm.size}px`,
      transitionDuration: `${_vm.duration}s`,
      transform: `rotate(${_vm.startingAngle}deg)`,
    })},_vm._l((_vm.items),function(item,index){return _c('div',{key:`${item.id}-${index}`,staticClass:"wheel-item",style:({
        transform: `rotate(${_vm.itemAngle * index}deg) skewY(${-(
          90 - _vm.itemAngle
        )}deg)`,
        background: item.background,
      })},[_c('div',{staticClass:"content",class:{ 'horizontal-content': _vm.horizontalContent },style:({
          transform: `skewY(${90 - _vm.itemAngle}deg) rotate(${
            _vm.itemAngle / 2
          }deg)`,
        })},[_c('div',{staticClass:"wheel-item-main",style:({ color: item.textColor })},[(_vm.showIcon(item))?_c('img',{staticClass:"w-icon",attrs:{"width":"40","src":require("@/assets/icon/uide/coin.svg"),"alt":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.getDisplayName(item.name)))])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }