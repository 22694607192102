<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header notice">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="goBack">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">抽獎結果</span>
            <div class="flexH width right">                
                &nbsp;
                            
            </div>
        </section>
        <!-- Main -->
        <section class="main p-0 p-40px card-wrapper">
            <div v-for="transaction in transactions" :key="transaction.id">
                <div class="card">
                    <div class="card-col">
                        <div class="card-title cNeutral500 fM">獲得時間</div>
                        <div class="card-content cNeutral900 fL">{{ tsToDatetime(transaction.created_at) }}</div>
                    </div>
                    <div class="card-col">
                        <div class="card-title cNeutral500 fM">抽獎結果</div>
                        <div class="card-content cNeutral900 fL">{{getRewardName(transaction)}}</div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
    name: "RewardDetail",
    components: {

    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            transactions: [],
        };
    },
    
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                // window.location.reload();
                next("/home");
            }
        });
    },
    mounted() {
        this.fetchRewardDetail();
    },
    computed: {
        ...mapState(["user", "userInfo"]),
        entryType() {
            return '中獎'
        },
    },
    methods: {
        tsToDatetime(ts) {
            return moment(ts).add(8, 'hours').format("YYYY/MM/DD HH:mm:ss");
        },
        fetchRewardDetail() {
            const config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${this.$route.params.id}/mini-game-reward`,
                method: "POST",
                data: {
                    uuid: this.user.userId,
                    member_id: this.userInfo.memberId,
                },
            };
            this.$http(config).then((res) => {
                this.transactions = res.data
            });
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        getRewardName(item) {
            try {
                if (item?.reward?.name) {
                    return item.reward.name
                }

                let context = item?.reward?.context
                if (context.length === 0) {
                    return '-'
                }

                context = JSON.parse(context)
                const { amount } = context
                if (amount !== undefined) {
                    return `雲林幣 ${amount} 枚`
                }

                return '-'
            } catch (error) {
                console.log('error', error);
                return '-'
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    gap: 12px;
    padding: 18px;
    display: flex;
    flex-direction: column;

    .card-col {
        display: flex;
        flex-direction: column;
    }

    .card-content {
        margin-top: 8px;
    }
}

.card-wrapper {
    padding: 80px 16px !important;
    gap: 16px;
    display: flex;
    flex-direction: column;
}
</style>
