<template>
  <div
    :id="`wheel-container-${randomIdRoulette}`"
    class="wheel-container"
    :class="[
      `indicator-${indicatorPosition}`,
      { 'wheel-container-indicator': displayIndicator },
      { 'wheel-container-shadow': displayShadow },
      { 'wheel-container-border': displayBorder },
    ]"
  >
    <!-- BASE WHEEL -->
    <div
      v-if="baseDisplay"
      class="wheel-base-container"
      :class="[{ 'wheel-base-container-shadow': baseDisplayShadow }]"
      :style="{
        width: `${baseSize}px`,
        height: `${baseSize}px`,
        background: `${baseBackground}`,
      }"
    >
      <div class="wheel-base">
        <slot name="baseContent" />
      </div>
      <div
        v-if="baseDisplayIndicator"
        class="wheel-base-indicator"
      />
    </div>
    <!-- WHEEL -->
    <div
      class="wheel"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
        transitionDuration: `${duration}s`,
        transform: `rotate(${startingAngle}deg)`,
      }"
    >
      <div
        v-for="(item, index) in items"
        :key="`${item.id}-${index}`"
        class="wheel-item"
        :style="{
          transform: `rotate(${itemAngle * index}deg) skewY(${-(
            90 - itemAngle
          )}deg)`,
          background: item.background,
        }"
      >
        <div
          class="content"
          :class="{ 'horizontal-content': horizontalContent }"
          :style="{
            transform: `skewY(${90 - itemAngle}deg) rotate(${
              itemAngle / 2
            }deg)`,
          }"
        >
          <div
            :style="{ color: item.textColor }"
            class="wheel-item-main"
          >
            <img class="w-icon" v-if="showIcon(item)" width="40" src="@/assets/icon/uide/coin.svg" alt="" />
            <span>{{ getDisplayName(item.name) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent ({
  name: "Roulette",
  emits: ["wheelStart", "wheelEnd"],
  props: {
    items: {
      type: Array,
      required: true,
    },
    firstItemIndex: {
      type: Object,
      required: false,
      default() {
        return { value: 0 };
      },
    },
    wheelResultIndex: {
      type: Object,
      required: false,
      default() {
        return { value: null };
      },
      validator(obj) {
        return typeof obj.value === "number";
      },
    },
    centeredIndicator: {
      type: Boolean,
      required: false,
      default: false,
    },
    indicatorPosition: {
      type: String,
      required: false,
      default: "top",
      validator(value) {
        return ["top", "right", "bottom", "left"].includes(value);
      },
    },
    size: {
      type: Number,
      required: false,
      default: 300,
    },
    displayShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    duration: {
      type: Number,
      required: false,
      default: 4,
    },
    resultVariation: {
      type: Number,
      required: false,
      default: 0,
      validator(value) {
        return value >= 0 && value <= 100;
      },
    },
    easing: {
      type: String,
      required: false,
      default: "ease",
      validator(value) {
        return ["ease", "bounce"].includes(value);
      },
    },
    counterClockwise: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontalContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayIndicator: {
      type: Boolean,
      required: false,
      default: true,
    },
    baseDisplay: {
      type: Boolean,
      required: false,
      default: false,
    },
    baseSize: {
      type: Number,
      required: false,
      default: 100,
    },
    baseDisplayShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    baseDisplayIndicator: {
      type: Boolean,
      required: false,
      default: false,
    },

    baseBackground: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      randomIdRoulette: 0,
      itemSelected: null,
      processingLock: false,
    };
  },
  computed: {
    itemAngle: function () {
      return 360 / this.items.length;
    },
    startingAngle: function () {
      if (this.centeredIndicator) {
        return (
          -1 * this.firstItemIndex.value * this.itemAngle - this.itemAngle / 2
        );
      } else {
        return -1 * this.firstItemIndex.value * this.itemAngle;
      }
    },
    degreesVariation: function () {
      if (!this.resultVariation) {
        return 0;
      }
      const minDegreesVariation =
        (((this.itemAngle / 2) * this.resultVariation) / 100) * -1;
      const maxDegreesVariation =
        ((this.itemAngle / 2) * this.resultVariation) / 100;
      // Return random value between min and max degrees variation
      return Number(
        (
          Math.random() * (maxDegreesVariation - minDegreesVariation) +
          minDegreesVariation
        ).toFixed(2)
      );
    },
    counterClockWiseOperator: function () {
      return this.counterClockwise ? -1 : 1;
    },
  },
  mounted() {
    this.randomIdRoulette = Number((Math.random() * (999999 - 1) +1).toFixed(0));
    this.$nextTick(() => {
      this.reset();
      document.querySelector(`#wheel-container-${this.randomIdRoulette} .wheel`).addEventListener("transitionend", () => {
        this.processingLock = false;
        this.$emit("wheel-end", this.itemSelected);
      });
    })
  },
  methods: {
    showIcon(item) {
      return item.name !== "銘謝惠顧";
    },
    getDisplayName(name) {
      return name === "銘謝惠顧" ? "銘謝\n惠顧" : name;
    },
    reset() {
      this.itemSelected = null;
      document.querySelector(
        `#wheel-container-${this.randomIdRoulette} .wheel`
      ).style.transform = `rotate(${this.startingAngle}deg)`;
    },
    launchWheel() {
      if (this.processingLock && this.itemSelected != null) {
        return;
      }
      this.processingLock = true;
      let wheelResult;
      if (this.wheelResultIndex.value !== null) {
        wheelResult = this.wheelResultIndex.value % this.items.length;
      } else {
        wheelResult = Math.floor(Math.random() * this.items.length + 1) - 1;
      }
      const wheelElt = document.querySelector(`#wheel-container-${this.randomIdRoulette} .wheel`);
      this.itemSelected = this.items[wheelResult];
      
      wheelElt.style.transform = `rotate(${
        this.counterClockWiseOperator * (360 * 3) +
        -(wheelResult) * this.itemAngle -
        this.itemAngle / 2 +
        this.degreesVariation
      }deg)`;
      this.$emit("wheel-start", this.itemSelected);
    },
  },
});
</script>

<style lang="scss" scoped>
.wheel-container,
.wheel-base,
.wheel-base-container,
.wheel-base-indicator {
  transition: transform 1s ease-in-out;
}
.wheel-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  max-width: 320px;

  &-indicator:before {
    content: "";
    position: absolute;
    z-index: 4;
    width: 0;
    height: 0;
    left: 153px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #6b2df5;
    transform: translateX(-50%);
  }

  &.indicator-top {
    transform: rotate(0deg);
  }
  &.indicator-right {
    transform: rotate(90deg);
    .wheel-base {
      transform: rotate(-90deg);
    }
  }
  &.indicator-bottom {
    transform: rotate(180deg);
    .wheel-base {
      transform: rotate(-180deg);
    }
  }
  &.indicator-left {
    transform: rotate(270deg);
    .wheel-base {
      transform: rotate(-270deg);
    }
  }

  &-border {
    border: 8px solid #6b2df5;
  }

  &-shadow {
    box-shadow: 5px 5px 15px -5px #000000;
  }
}
.wheel-base-container {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);

  &-shadow {
    box-shadow: 5px 5px 15px -5px #000000;
  }

  .wheel-base {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .wheel-base-indicator {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .wheel-base-indicator:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid black;
    transform: translateX(-50%);
  }
}

.wheel {
  background: white;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;

  &.easing-ease {
    transition: transform cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.easing-bounce {
    transition: transform cubic-bezier(0.49, 0.02, 0.52, 1.12);
  }

  &-border:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    border-radius: 50%;
    background-image: linear-gradient(
      to left,
      black 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 3px 1px;
    /* background:linear-gradient(red,purple,orange); */
    -webkit-mask: radial-gradient(transparent 65%, #000 66%);
    mask: radial-gradient(transparent 65%, #000 66%);
  }

  &-item {
    overflow: hidden;
    position: absolute;
    top: -29px;
    right: -29px;
    width: 59%;
    height: 59%;
    transform-origin: 0% 100%;
  }
  &-item:nth-child(odd) {
    background-color: #fff;
  }
  &-item:nth-child(even) {
    background-color: #ffc403;
  }

  .content {
    position: absolute;
    left: -100%;
    width: 200%;
    height: 200%;
    text-align: center;
    transform: skewY(30deg) rotate(0deg);
    padding-top: 24px;

    &.horizontal-content {
      left: initial;
      right: 100%;
      width: 50%;
      height: 250%;
      text-align: right;
      span {
        display: block;
        transform: rotate(270deg);
      }
    }
  }

  .wheel-item {
    span {
      font-size: 20px;
      font-weight: bold;
      white-space: pre-line;
    }
  }
  .wheel-item-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-8px, 27px);
  }
  .w-icon {
    margin-bottom: 4px;
  }
}
</style>